import { NavigationGuard } from 'vue-router'
import { Route } from '@/modules/base/config/routesConfig'

export const notFoundPrefetchGuard: NavigationGuard = async (to) => {
  if (!to.meta.prefetchGuard) {
    return true
  }

  const prefetches = to.meta.prefetchGuard(to)

  try {
    await Promise.all(prefetches)
    return true
  } catch (error) {
    return {
      name: Route.NotFound,
    }
  }
}
